import React from 'react'
import stripe from '../img/Trustpilot.png'
import discord from '../img/Screenshot (251).png'
import tradingview from '../img/Screenshot (252).png'
import forbes from '../img/Screenshot (253).png'


function Trusted() {
  return (
    <section id="trusted">
        <div className="trusted-container">
            <h6>Trusted By Thousands Of People</h6>
            <ul className="trusted__logos">
                <li className="trusted__logo">
                    <img src={stripe} />
                </li>
                <li className="trusted__logo">
                    <img src={discord} />
                </li>
                <li className="trusted__logo">
                    <img src={tradingview} />
                </li>
                <li className="trusted__logo">
                    <img src={forbes} />
                </li>
            </ul>
        </div>
    </section>
  )
}

export default Trusted
